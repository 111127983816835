.confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
}

.confirmation-content {
    text-align: center;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.confirmation-content h1 {
    color: #4CAF50;
    margin-bottom: 20px;
}

.confirmation-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 30px;
}

.confirmation-content button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.confirmation-content button:hover {
    background-color: #367c39;
}
