/* Reset some default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #000; /* Black background */
    margin: 0;
    padding: 0;
}

/* Custom styles for FindJob component */
.find-job-box {
    background: linear-gradient(45deg, #007bff, #8a2be2);
    padding: 20px;
    margin-top: 90px;
    margin-bottom: 40px;
    margin-left: 40px;
    margin-right: 40px;
    text-align: center;
    border-radius: 15px; /* Rounded corners */
    color: #fff; /* White text color */
}

.find-job-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
}

.find-job-heading {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 10px;
}

.custom-select-container {
    width: 100%;
    max-width: 300px;
    margin: 0 auto 20px;
}

.custom-select {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 20px; /* Rounded corners */
    background-color: #fff; /* White background */
    font-size: 16px;
    color: #333;
    outline: none;
    transition: border-color 0.3s ease;
}

.custom-select:focus {
    border-color: #0056b3;
}

.job-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Display 3 items in a row */
    grid-gap: 40px; /* Adjust the gap as needed */
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 40px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
    min-height: 500px; /* Minimum height for the content */
}

.job-list-item {
    background: linear-gradient(45deg, #007bff, #8a2be2);
    color: white;
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    line-height: 1.5;
    text-align: left;
}


/* Modal styles */
.job-description-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
}

.job-description-content {
    background-color: #fff; /* White background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    text-align: left;
    position: relative;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .job-list {
        grid-template-columns: 1fr; /* Display 1 item in a column on mobile */
        margin-right: 40px;
        margin-left: 40px;
    }

    /* Center the job list on the screen */
    .job-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
