/* UserProfile.css */

.user-profile-container {
    padding: 20px;
    margin-top: 60px; /* Adjust based on the height of your Navbar */
    text-align: center;
}

.user-info {
    background-color: #f2f2f2;
    border-radius: 8px;
    display: inline-block;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-info p {
    color: #333;
    font-size: 18px;
    line-height: 1.6;
}

.user-info strong {
    color: #007bff;
}
