/* Navigation Bar Container */
.navbar {
    background: linear-gradient(45deg, #007bff, #8a2be2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    justify-content: space-between; /* Centered navigation items */
    align-items: center;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

/* Placeholder Name */
.navbar-brand {
    color: #fff;
    font-size: 24px; /* Increased font size for brand name */
    font-weight: bold;
    cursor: pointer;
}

/* Navigation Menu */
.nav-ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
}

/* Navigation Menu List Item */
.nav-li {
    margin-right: 20px;
}

/* Push all items after this to the right */
.nav-li.about {
    margin-left: auto;
}

/* Navigation Menu Link */
.nav-link, .sign-out-btn {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px; /* Increased font size for links */
    transition: color 0.3s ease;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.nav-link:hover, .sign-out-btn:hover {
    color: #ccc;
}

/* Style adjustments for buttons to look like links */
.nav-link-button {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    cursor: pointer;
}
