/* Styles for the Home Page */

/* Center-align the content */
main {
    text-align: center;
    padding: 20px;
    margin-top: 40px; /* Increase the top margin */
}

/* Style the main heading */
h1 {
    font-size: 36px;
    margin-bottom: 20px;
}

/* Style the buttons container */
.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

/* Style the big buttons */
.big-button {
    padding: 20px 40px;
    font-size: 24px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effect for big buttons */
.big-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

/* Style the "or" text */
.or-text {
    font-size: 18px;
    color: #555;
}
