/* Footer.css */
footer {
    background: linear-gradient(45deg, #007bff, #8a2be2);
    color: white;
    text-align: center;
    padding: 1rem;
    width: 100%;
    position: sticky; /* Change 'absolute' to 'sticky' */
    bottom: 0;
}
