/* About.css */

.about-main {
    padding: 20px;
    margin-top: 60px; /* Adjust based on the height of your Navbar */
    text-align: center;
}

.about-content {
    max-width: 800px;
    margin: 0 auto; /* Center the content */
    text-align: left;
    font-size: 18px;
    line-height: 1.6;
}

.about-content p {
    margin-bottom: 20px;
}
