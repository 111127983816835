/* AuthStyles.css */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh;
    background-color: #f4f7f6;
  }
  
  .auth-form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
    width: 100%;
    max-width: 400px;
    text-align: center;
  }
  
  .auth-form h1 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .auth-form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
  }
  
  .auth-form button {
    width: calc(100% - 20px);
    padding: 12px;
    border: none;
    background-color: #4CAF50;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 10px;
  }
  
  .auth-form button:hover {
    background-color: #45a049;
  }
  
  .auth-form p {
    color: #666;
    margin-top: 15px;
  }
  
  .auth-form a {
    color: #007bff;
    text-decoration: none;
  }
  
  .auth-form a:hover {
    text-decoration: underline;
  }
  
  .auth-error {
    color: red;
    margin-bottom: 15px;
  }

  /* ... (existing styles) */

.google-signin-button {
    background-color: #4285F4;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .google-signin-button:hover {
    background-color: #357ae8;
  }
  

  



  
  /* Responsive Design */
  @media (max-width: 600px) {
    .auth-form {
      width: 90%;
    }
  }
  