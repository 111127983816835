/* PostJob.css */

/* Main container */
.post-job-main {
    padding: 20px;
    margin-top: 60px; /* Adjust based on the height of your Navbar */
    text-align: center;
    background-color: #f4f4f4; /* Background color for the main container */
}

/* Form container */
.post-job-container {
    max-width: 600px;
    margin: 0 auto; /* Center the form */
    background-color: #fff; /* Background color for the form container */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form input fields */
.post-job-form input[type="text"],
.post-job-form input[type="email"],
.post-job-form textarea,
.post-job-form select,
.post-job-form .phone-input {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Location dropdown */
.location-dropdown {
    width: 100%;
    padding: 12px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
    background-image: linear-gradient(45deg, transparent 50%, #007bff 50%), linear-gradient(135deg, #007bff 50%, transparent 50%);
    background-position: calc(100% - 20px) center, calc(100% - 15px) center;
    background-size: 10px 10px;
    background-repeat: no-repeat;
    cursor: pointer;
    color: #007bff;
    font-weight: bold;
    font-size: 16px;
}

/* Location dropdown hover effect */
.location-dropdown:hover {
    background-image: linear-gradient(45deg, transparent 50%, #0056b3 50%), linear-gradient(135deg, #0056b3 50%, transparent 50%);
}

/* Phone input styling */
.post-job-form .phone-input {
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

/* Submit button */
.post-job-form button {
    padding: 12px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

/* Submit button hover effect */
.post-job-form button:hover {
    background-color: #0056b3;
}

/* Success message */
.success-message {
    color: green;
    font-weight: bold;
}

/* Error message */
.error-message {
    color: red;
    font-weight: bold;
}
