.payment-form-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
}

.payment-form-title {
    text-align: center;
    color: #333;
}

.payment-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.payment-form-label {
    font-size: 16px;
    color: #333;
}

.payment-form-card-element {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.payment-form-submit {
    padding: 12px;
    border: none;
    border-radius: 4px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.payment-form-submit:disabled {
    background-color: #ccc;
    cursor: default;
}

.error-message {
    color: #ff6b6b;
    text-align: center;
}

.success-message {
    color: #48bb78;
    text-align: center;
}
